<template>
    <div class="flex flex-1 flex-col max-h-full overflow-hidden text-base-content" v-allow:auth="'users.read'" >
        <sub-header :backButton="true" />
        <div class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar min-h-full overflow-y-auto">
            <div class="flex items-center justify-center flex-1 bg-card-bg py-16 rounded-md flex-col" v-if="loading"><Loader class="my-5" :size="8" /> Loading</div>
            <div v-else class="bg-card-bg rounded-xl shadow p-6 flex flex-col gap-5">
                <div  class="flex justify-center">
                    <div class="flex gap-6 flex-col flex-1" v-if="userDetails">
                        <div class="flex flex-col gap-2">
                            <span class="text-base-content-300 text-md"> User Name </span>
                            <neo-input :disabled="!userPermission"  class="rounded-lg h-10 w-full lg:w-2/6" placeholder="Group Name" bg="white" v-model="userDetails.user_name"> </neo-input>
                        </div>
                        <div class="flex flex-col gap-2">
                            <span class="text-base-content-300 text-md"> Email </span>
                            <neo-input disabled  class="rounded-lg h-10 w-full lg:w-2/6" placeholder="Assigned Client" bg="white" v-model="userDetails.email"> </neo-input>
                        </div>
                        <div class="flex flex-col w-full lg:w-2/6 flex-1  xl:flex-row gap-4">
                            <div class="flex flex-col  gap-2">
                                <span class="text-base-content-300 text-md"> Assigned Roles </span>
                                <vue-multiselect
                                :disabled="!userPermission  || !checkPermission('role.read')"
                                class="h-14"
                                    :multiple="true"
                                    :config="{ limit: 1 }"
                                    placeholder="Select a Roles to add"
                                    :show-labels="false"
                                    :maxHeight="200"
                                    :options="roleOptions"
                                    track-by="id"
                                    label="title"
                                    v-model="roleValue"
                                    :loading="loading"
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#e8e8e8',
                                    }"
                                    showCheckBox
                                    :value="roleValue"
                                />
                            </div>
                            <div class="flex flex-col  flex-1  gap-2">
                                <span class="text-base-content-300 text-md"> Assigned Groups </span>

                                <vue-multiselect
                                    class="h-14"
                                    :disabled="!userPermission || !checkPermission('groups.read')"
                                    :multiple="true"
                                    :config="{ limit: 1 }"
                                    showCheckBox
                                    placeholder="Select a Groups to add"
                                    :show-labels="false"
                                    :options="groupOptions"
                                    track-by="id"
                                    label="title"
                                    v-model="groupValue"
                                    :loading="loading"
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#e8e8e8',
                                    }"
                                    :value="groupValue"
                                />
                            </div>
                        </div>
                    </div>
                     <div class="flex gap-6 flex-col flex-1" v-if="userDetails">
                        <div class="flex">
                        <span class="font-bold p-3 pl-0">API Tokens</span>
                        <neo-button v-if="!isTokenGenerated && !isApiKey" text="Generate" type="primary" class="text-white-text"  @click="generateToken" />
                        <neo-button v-if="isTokenGenerated || isApiKey" text="Regenerate" type="primary" class="text-white-text ml-4"  @click="regenerateToken" />
                        <neo-button v-if="isTokenGenerated || isApiKey" text="Revoke" type="outline" class="ml-4 text-blue-600 bg-white" @click="revokeToken"/>
                        </div>
                        <div v-if="isTokenGenerated"><p>If You loase or forget your secret key, you cannot retrive  it. Generate a new Access and Secret keys.</p></div>
                        <div class="flex flex-col gap-2" v-if="isTokenGenerated ||isApiKey">
                            <span class="text-base-content-300 text-md"> API Key </span>
                            <div class="form-control relative">
                                <input type="text" v-model="apiToken" ref="api" class="input rounded-lg h-10 w-full lg:w-full input-bordered border-box-stroke focus:border-primary "/>
                                <font-awesome-icon icon="copy" ref="copy" class="text-blue-500 hover:text-blue-600 absolute right-4 top-3 cursor-pointer outline-none " @click="copyApiKey()" v-tippy='{ arrow : true, theme : "light bordered", trigger : "click"}' content="Api key copied"/>
                            </div>
                        </div>    
                        <div class="flex flex-col gap-2" v-if="isTokenGenerated">
                            <span class="text-base-content-300 text-md"> Secret </span>
                            <div class="form-control relative" :style="`${disableCopy ? 'pointer-events: none; cursor:not-allowed' : ''}`">
                                <input type="password" ref="secret" v-model="secretkey" :disabled="disableCopy"  class="input rounded-lg h-10 w-full lg:w-full input-bordered border-box-stroke focus:border-primary " :class="`${disableCopy ? 'text-gray-500':''}`"  :style="`${disableCopy ? 'pointer-events: none; cursor:not-allowed' : ''}`"/>
                                <span class="text-sm ml-2 top-2 underline" style="position:absolute; right:38px; cursor:pointer"  v-if="!disableCopy" :class="disableCopy ? 'text-gray-500' : 'text-blue-600' " @click="toggleKey()">{{!isKeyVisible ? 'Show' : 'Hide'}}</span>
                                <font-awesome-icon icon="copy" ref="btn" class="absolute right-4 top-3  cursor-pointer outline-none " @click="copySecretKey()" v-tippy='{ arrow : true, theme : "light bordered", trigger : "click", allowHTML:true}' content="Api Secret key copied" :class="`${disableCopy ? 'text-gray-500' : 'text-blue-500 hover:text-blue-600'}`"/>
                            </div>
                        </div>
                    </div>
               
                </div>

                <div class="mt-4 flex justify-end max-w-full gap-4">
                    <!-- <div class="h-full">
                        <button type="button" class="text-white-text bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2"
                         @click="deleteUser">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 mr-2">
                                <path
                                    fill-rule="evenodd"
                                    d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                            Delete User
                        </button>
                    </div> -->
                    <neo-button v-if="checkPermission('users.edit')" text="Delete User" icon="trash" type="error" class="text-white-text"  v-allow="'users.delete'" @click="deleteUser" />
                    <neo-button text="Save" icon="check" type="primary" class="text-white-text" v-allow="'users.edit'" @click="updateUser" />
                </div>
            </div>
        </div>

        <!-- delete popup confirm -->

        <modal-confirm title="Are you sure?" message="Please confirm you're about to delete the field." ref="confirm-popup"> </modal-confirm>
    </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Input from "@/components/input";
import Button from "@/components/button";
import axios from "@/axios";
import Loader from "@/components/loader";
// import Multiselect from "@/components/vue-multiselect";
// import Multiselect from "vue-multiselect";
import Dropdown from "@shared/components/dropdown-base";
const modalConfirm = () => import("@/components/modal-confirm");
import { checkPermission } from "@shared/utils/functions";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


export default {
    name: "field-table-data-view",
    components: {
        SubHeader,
        "neo-input": Input,
        "neo-button": Button,
        "vue-multiselect": Dropdown,
        modalConfirm,
        Loader,
        'font-awesome-icon': FontAwesomeIcon,
    },
    props: {},
    data: () => ({
        roleValue: [],
        groupValue: [],
        roleOptions: [],
        groupOptions: [],
        loading: false,
        userDetails: null,
        tenant_id: null,
        user_id: null,
        allRemoved: false,
        oldRoleValue:[],
        oldGroupValue:[],
        apiToken: null,
        secretkey: null,
        isKeyVisible: false,
        isTokenGenerated: false,
        disableCopy: false,
        isApiKey: false
    }),
    async mounted() {
        this.user_id = this.$route.params.id;
        this.tenant_id = this.$store.getters.getTenantId;
        await this.fetchUserDetails();
        await this.fetchUserGroups();
        await this.fetchUserRoles();
        await this.getToken();
    },
    computed:{
        userPermission(){
            return this.checkPermission('users.edit')
        }
    },
    watch:{
        groupValue(newVal,oldVal){
            
        oldVal.forEach((e)=>{
            this.oldGroupValue.push(e)
        })
           console.log("111",this.oldGroupValue)
           console.log("newVal",newVal)
           console.log("old val group",oldVal)
        },
        roleValue(newVal,oldVal){
            oldVal.forEach((e)=>{
            this.oldRoleValue.push(e)
            })
            console.log("1112",this.oldRoleValue)
            console.log("newVal",newVal)
            console.log("old val role",oldVal)
        }
    },
    methods: {
        checkPermission,
        async fetchUserGroups() {
            if (!this.checkPermission('groups.read')) return
            let { data } = await axios.get(`group/tenant/${this.tenant_id}`);
            this.groupOptions = data;
        },
        async fetchUserRoles() {
            if (!this.checkPermission('role.read')) return
            let { data } = await axios.get(`role/tenant/${this.tenant_id}`);
            this.roleOptions = data;
        },
        async fetchUserDetails() {
            
            this.loading = true;
            const url = `/user/tenant/${this.tenant_id}/user/${this.user_id}`;
            try {
                const { data } = await axios.get(url);
                // // todo deependra fix this
                // this.roleOptions?.forEach((el) => {
                //     data?.role_ids?.forEach((role) => {
                //         if (role.role_id === el.id) {
                //             this.roleValue.push(el);
                //         }
                //     });
                // });
                // // todo deependra fix this
                // this.groupOptions?.forEach((el) => {
                //     data?.group_ids?.forEach((role) => {
                //         if (role.group_id === el.id) {
                //             console.log(role.id, el.group_id, "asdnajsnd");
                //             this.groupValue.push(el);
                //         }
                //     });
                // });
                this.roleValue = data.role_ids.map(el => ({...el, id: el.role_id}))
                this.groupValue = data.group_ids.map(el => ({...el, id: el.group_id}))
                this.userDetails = data;
            } catch (error) {
                this.$toast.warning(error.response.data.detail || "User Details not found");
            }
            this.loading = false;
        },
        async updateUser() {
            const group_ids = this.groupValue.map((x) => ({ group_id: x.id }));
            const role_ids = this.roleValue.map((x) => ({ role_id: x.id }));
            try {
                let { data } = await axios.put("user/", {
                    id: this.userDetails.id,
                    email: this.userDetails.email,
                    user_name: this.userDetails.user_name,
                    group_ids: group_ids,
                    role_ids: role_ids,
                });
                this.$toast.success(data.message || "user updated");
                this.$router.back();
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to update user");
                console.log("error", error);
                
                if(!this.groupValue.length){
                    const uniqueArray = []
                    const uniqueIds = new Set();
                    for(const item of this.oldGroupValue){
                        
                        if (!uniqueIds.has(item.id)) {
                            uniqueIds.add(item.id);
                            uniqueArray.push(item);
                            }
                    }
                    this.groupValue = uniqueArray;
                }
                if(!this.roleValue.length){
                    const uniqueArray = []
                    const uniqueIds = new Set();
                    for(const item of this.oldRoleValue){
                        if (!uniqueIds.has(item.id)) {
                            uniqueIds.add(item.id);
                            uniqueArray.push(item);
                            }
                    }
                    this.roleValue = uniqueArray;
                }
                
                this.allRemoved = true;
            }
        },
        async deleteUser() {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This user  will be deleted permanently. Please confirm to continue deleting this user.",
            });
            if (promise) {
                try {
                    console.log("delete user api called on id:", this.user_id);
                    let { data } = await axios.delete(`user/${this.user_id}`);
                    this.$toast.success(data.message || "user deleted");
                    this.$router.back();
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to delete user deleted");
                }
            }
            this.$refs["confirm-popup"].close();
        },
        async generateToken() {
            const apiToken = await axios.get(`user-api-token/generate?user_id=${this.user_id}`);
            if(apiToken.status) {
                this.apiToken = apiToken?.data?.data?.api_key;
                this.secretkey = apiToken?.data?.data?.secret;
                this.isTokenGenerated = true;
                this.$toast.message(apiToken.message)
            }  
        },
        async regenerateToken() {
             this.disableCopy = false;
            const apiToken = await axios.post('user-api-token/re-generate',{'api_key':this.apiToken, 'user_id':this.user_id});
            if(apiToken.status) {
                this.apiToken = apiToken?.data?.data?.api_key;
                this.secretkey = apiToken?.data?.data?.secret;
                this.isTokenGenerated = true;
                this.$toast.message(apiToken.message)
            }  
        },
        async getToken() {
             this.disableCopy = false;
            const apiToken = await axios.get(`user-api-token?user_id=${this.user_id}`);
            if(apiToken.status&& apiToken?.data?.data?.length>0) {
                this.apiToken = apiToken?.data?.data[0]?.api_key;
                this.isApiKey= true;
            }  
        },
        toggleKey() {
            this.$refs.secret.type = this.$refs.secret.type === "text" ? "password" : "text";
            this.isKeyVisible = !this.isKeyVisible;
        },
        copySecretKey() {
            this.$refs.secret.type = "text";
            const element = this.$refs.secret;
            element.select();
            document.execCommand('copy');
            var that = this;
            that.$refs.btn._tippy.color ="green"
            setTimeout(function() { that.$refs.btn._tippy.show(); }, 100);
            this.keyCopied();
            this.disableCopy = true;
        },
        copyApiKey() {
            const element = this.$refs.api;
            element.select();
            document.execCommand('copy');
            var that = this;
            setTimeout(function() { that.$refs.copy._tippy.show(); }, 100);
            this.apiCopied();
        },
        apiCopied() {
            var that = this;
            setTimeout(function() { that.$refs.copy._tippy.hide(); }, 2000);
        },
        keyCopied() {
            var that = this;
            this.$refs.secret.type = "password";
            setTimeout(function() { that.$refs.btn._tippy.hide(); }, 2000);
        },
        async revokeToken() {
            this.isTokenGenerated = false;
            await this.getToken()
        }
    },
};
</script>
<style lang="scss">
.tippy-tooltip {
    &.light-theme{
        color: green;
    }
}
</style>
<style scoped lang="scss">
.show{
    position:absolute;
}

</style>
